import React, { useState, useEffect, useRef, useCallback } from 'react'
import MainLayout from '../layouts/mainLayout'
import {
  Container,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import InputTypes from '../constants/editabletypes.constants'
import reviewTypeConstants from '../constants/reviewType.constants'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import {
  makeEditableInvalid,
  makeEditableValid,
  makeEditableOptional,
  createNewPage,
  getOverflowContent,
  getDocumentHTML,
  addEditableClickHandlers,
  appendPage,
  getHtmlForEditableId,
  removeNonMandatoryFieldsOnCompletion,
} from '../utils/domHelpers'
import { SubNavButton } from '../components/navigation'
import wizardService from '../services/wizard.service'
import { useHistory, useLocation } from 'react-router-dom'
import ScrollToTop from '../components/navigation/ScrollToTop'
import { useNavigation } from '../contexts/navigationContext'
import mergeTypes from '../constants/mergetypes.constants'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueSnackbar } from '../actions/notification.actions'
import queryString from 'query-string'
import HtmlDialog from '../components/editableDialogs/htmlDialog'
import DatePickerDialog from '../components/editableDialogs/datePickerDialog'
import TimePickerDialog from '../components/editableDialogs/timePickerDialog'
import DropdownDialog from '../components/editableDialogs/dropdownDialog'
import FreetypeDialog from '../components/editableDialogs/freetypeDialog'
import CarouselDialog from '../components/editableDialogs/carouselDialog'
import rolesConstants from '../constants/roles.constants'
import tokenConstants from '../constants/token.constants'
import { DocumentStatus } from '../utils/documentHelper'
import LoadingPage from '../components/loadingPage'
import { downloadFile } from '../utils/file'
import editabletypesConstants from '../constants/editabletypes.constants'

const useStyles = makeStyles(theme => ({
  pages: {
    padding: '24px',
  },
  previewDisclaimer: {
    color: '#a1a1a1',
    textAlign: 'center',
    margin: 0,
  },

  contentContainer: {
    paddingTop: '16px',
  },
  unsavedChangesChip: {
    color: theme.palette.getContrastText(theme.palette.navigation.main),
    fontWeight: 'bold',
  },
  modalSaveButtonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modalSaveButtonWrapper: {
    position: 'relative',
  },
  hide: {
    display: 'none',
  },
}))

function Wizard() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { returnUrl, returnToUrl } = useNavigation()
  const [progress, setProgress] = useState(0)
  const pagesRef = useRef(null)
  const [editableLookups, setEditableLookups] = useState({})
  const [htmlEditorState, setHtmlEditorState] = useState({})
  const [carouselState, setCarouselState] = useState({})
  const [documentData, setDocumentData] = useState({})
  const [mergefieldData, setMergefieldData] = useState({})
  const [showEditableDialog, setShowEditableDialog] = useState(false)
  const [selectedEditable, setSelectedEditable] = useState(null)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [managerMadeChanges, setManagerMadeChanges] = useState(false)
  const [isConfirmExitModalOpen, setIsConfirmExitModalOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isCompleting, setIsCompleting] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [openReviewModal, setOpenReviewModal] = useState(false)
  const { user } = useSelector(state => state.auth)
  const [documentStatus, setDocumentStatus] = useState(null)
  const [prepopulatedCount, setPrepopulatedCount] = useState(0)
  const [reviewType, setReviewType] = useState(
    reviewTypeConstants.AdviserReview
  )
  const [
    completionConfirmationModal,
    setCompletionConfirmationModal,
  ] = useState(false)

  const isBusinessUser = user.profile[tokenConstants.ROLES].includes(
    rolesConstants.BUSINESS
  )
  const isAdviser = user.profile[tokenConstants.ROLES].includes(
    rolesConstants.HR
  )

  const hasHrUserRole = user.profile[tokenConstants.ROLES].includes(
    rolesConstants.HR
  )

  const isManager = !isAdviser && isBusinessUser

  const documentInReview = documentStatus === DocumentStatus.AdviserReview
  const documentReviewEnded = documentStatus === DocumentStatus.ReviewEnded
  const documentCompleted = documentStatus === DocumentStatus.Completed
  const documentInProgress = documentStatus === DocumentStatus.InProgress
  const creatingNewDocument = location.pathname.includes('new')

  const withUnsavedChanges = callback => {
    setManagerMadeChanges(
      (reviewType === reviewTypeConstants.AdviserReview ||
        reviewType === reviewTypeConstants.AdviserCompletion) &&
        isManager
    )

    if (documentInProgress && !documentInReview) {
      setDocumentStatus(DocumentStatus.InProgress)
    }
    if (!unsavedChanges) {
      setUnsavedChanges(true)
    }

    return callback
  }

  const getAllEditables = () => {
    if (!pagesRef.current) return []
    return Array.from(pagesRef.current.querySelectorAll('[data-tag-id]'))
  }

  const processPage = useCallback(
    ({ page, header, footer }) => {
      const overflowContent = getOverflowContent(page)

      if (overflowContent.length <= 0) return

      const newPage = createNewPage({
        content: overflowContent,
        header: header,
        footer: footer,
        fontSize: documentData.fontSize,
        fontFamily: documentData.fontFamily,
      })
      appendPage(pagesRef.current, newPage)

      processPage({ page: newPage, header, footer })
    },
    [documentData]
  )

  const selectEditable = e => {
    const { target } = e

    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector
    }

    if (!Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        var el = this

        do {
          if (Element.prototype.matches.call(el, s)) return el
          el = el.parentElement || el.parentNode
        } while (el !== null && el.nodeType === 1)
        return null
      }
    }

    const closest = target.closest('[data-tag-id]')
    if (closest) {
      const id = closest.getAttribute('data-tag-id')
      const bounds = closest.getBoundingClientRect()

      setSelectedEditable({
        id,
        label: editableLookups[id].name,
        description: editableLookups[id].description,
        inputTypeId: editableLookups[id].inputTypeId,
        options: editableLookups[id].options,
        coords: { x: bounds.left, y: bounds.top },
        DOMElement: closest,
        isMandatory: editableLookups[id].isMandatory,
      })
      setShowEditableDialog(true)
    }
  }

  const deselectEditable = () => {
    setSelectedEditable(null)
    setShowEditableDialog(false)
    refreshPages()
  }

  const setupPages = useCallback(
    ({ content, header, footer }) => {
      const startingPage = createNewPage({
        isFirstPage: true,
        content: content,
        header: header,
        footer: footer,
        fontSize: documentData.fontSize,
        fontFamily: documentData.fontFamily,
      })
      appendPage(pagesRef.current, startingPage)
      processPage({ page: startingPage, header, footer })
    },
    [processPage, documentData]
  )

  const getDocumentDataFromApiAsync = async () => {
    const parsedQuery = queryString.parse(location.search)
    const {
      existingDocumentGuid,
      documentGuid,
      templateId,
      caseId,
      eventSetupId,
      workflowComponentId,
      workflowVersion,
      fromAddressUserId,
    } = parsedQuery

    if (
      (!creatingNewDocument && !existingDocumentGuid) ||
      (creatingNewDocument && (!documentGuid || !templateId))
    ) {
      history.push('/oops')
      dispatch(
        enqueueSnackbar({
          message: 'Document request missing required parameters',
          options: {
            variant: 'error',
          },
        })
      )
      return
    }

    let response
    try {
      if (creatingNewDocument) {
        response = await wizardService.createDocument({
          documentTemplateId: templateId,
          documentGuid,
          caseId,
          fromAddressUserId,
        })
      } else {
        response = await wizardService.continueDocument({
          existingDocumentGuid,
        })
        setManagerMadeChanges(response.documentData.inReviewWithChanges)
      }
    } catch (error) {
      console.error(error)
      history.push('/oops')
      dispatch(
        enqueueSnackbar({
          message: 'Could not load document. Please try again.',
          options: {
            variant: 'error',
          },
        })
      )
      return
    }

    if (!response) {
      history.push('/oops')
      dispatch(
        enqueueSnackbar({
          message: 'Could not load document. Please try again.',
          options: {
            variant: 'error',
          },
        })
      )
      return
    }

    const {
      documentData = null,
      caseData = null,
      employeeData = null,
      officeAddressData = null,
    } = response

    return response
  }

  const pagesRefCallback = useCallback(
    node => {
      if (node == null) return

      pagesRef.current = node
      ;(async function setupDocumentDataFromApiAsync() {
        await getDocumentDataFromApiAsync().then(response => {
          setMergefieldData(response)
          setDocumentData(response.documentData)
        })
      })()
    },
    [pagesRef]
  )

  useEffect(() => {
    if (!Object.keys(documentData).length) return

    const { contentHtml = '', branding = {} } = documentData
    const { headerHtml = '', footerHtml = '' } = branding

    setupPages({
      content: contentHtml,
      header: headerHtml,
      footer: footerHtml,
    })
    setupEditables()
  }, [documentData])

  const setupEditables = () => {
    const editableDetails = {}
    const editableHtmlEditorState = {}
    const creatingNewDocument = location.pathname.includes('new')

    setDocumentStatus(documentData.status)
    setReviewType(documentData.reviewType)

    const allEditables = getAllEditables()

    documentData.editableRegions.forEach(editableRegion => {
      const { editableRegionId, ...rest } = editableRegion
      editableDetails[editableRegionId] = {
        ...rest,
      }
      // set up html editor state
      if (editableRegion.inputTypeId === InputTypes.HtmlEditor) {
        const editorStateFromHtml =
          creatingNewDocument && editableRegion.prePopulatedState
            ? editableRegion.prePopulatedState
            : getHtmlForEditableId(allEditables, editableRegionId)
        editableHtmlEditorState[editableRegionId] = editorStateFromHtml
      }
    })

    setEditableLookups(editableDetails)
    setHtmlEditorState(editableHtmlEditorState)
  }

  useEffect(() => {
    const allEditables = getAllEditables()
    addEditableClickHandlers(allEditables, selectEditable)
  }, [editableLookups])

  const calculateProgress = useCallback(() => {
    const allEditables = getAllEditables()
    let nonMandatoryCount = 0

    if (allEditables.length && Object.keys(editableLookups).length) {
      const completed = allEditables.filter(x => {
        const tagId = x.getAttribute('data-tag-id')
        const editableLookup = editableLookups[tagId]
        if (!editableLookup) {
          console.error(
            `editable ID: ${tagId} does not exist but is present in the document!`
          )
          return false
        }

        const inputTypeId = editableLookup?.inputTypeId
        const name = editableLookup?.name
        const prePopulatedState = editableLookup?.prePopulatedState
        const innerText = x.innerText
        const isMandatory = editableLookup?.isMandatory

        let completed = false
        if (isMandatory) {
          if (inputTypeId === InputTypes.HtmlEditor && prePopulatedState) {
            const editorHtml = htmlEditorState[tagId]
            completed = editorHtml !== prePopulatedState
          } else {
            if(innerText !== null && name !== null)
            {
              completed = innerText.toLowerCase() !== name.toLowerCase()
            } else {
              completed = innerText !== name
            }
          }
        }
        if (!isMandatory) {
          ++nonMandatoryCount
        }

        return !!innerText && completed

      }).length //check completed length

      
      const progress = Math.round(
        (100 / (allEditables.length - nonMandatoryCount)) * completed
      )

      setProgress(isNaN(progress) ? 0 : progress)

    }
  }, [editableLookups, htmlEditorState])

  // pre-populate editable values
  useEffect(() => {
    if (!creatingNewDocument || prepopulatedCount > 2) {
      return
    }

    setPrepopulatedCount(count => count + 1)

    const editableList = getAllEditables()

    editableList.forEach(editable => {
      const id = editable.getAttribute('data-tag-id')
      editable.setAttribute('is-mandatory', editableLookups[id].isMandatory)
      const editableMergeData =
        editableLookups[id] && editableLookups[id].editableMergeField
      if (!editableLookups[id].isMandatory) {
        makeEditableOptional(editable)
      }

      // merge data depending on merge type
      if (mergefieldData && editableMergeData) {
        let mergedValue
        switch (editableMergeData.type) {
          case mergeTypes.caseData:
            mergedValue =
              (mergefieldData &&
                mergefieldData.caseData &&
                mergefieldData.caseData[editableMergeData.value]) ||
              ''
            break
          case mergeTypes.employeeData:
            mergedValue =
              (mergefieldData &&
                mergefieldData.employeeData &&
                mergefieldData.employeeData[editableMergeData.value]) ||
              ''
            break
          case mergeTypes.officeAddress:
            mergedValue =
              (mergefieldData &&
                mergefieldData.officeAddressData &&
                mergefieldData.officeAddressData[editableMergeData.value]) ||
              ''
            break
          default:
            mergedValue = editableLookups[id].name
        }

        // if merged value doesnt exist, set to editable name
        if (!mergedValue) {
          editable.innerText = editableLookups[id].name
          makeEditableInvalid(editable)
          return
        }
        if (/date/i.test(editableMergeData.value))
          mergedValue = new moment(mergedValue).format('DD/MM/YYYY')
        else if (/time/i.test(editableMergeData.value))
          mergedValue = new moment(mergedValue).format('HH:mm')

        editable.innerText = mergedValue
        makeEditableValid(editable)
      }
    })
    calculateProgress()
  }, [mergefieldData, editableLookups, calculateProgress])

  const updateDropdownValue = value => {
    if (!value) {
      resetDropdownValue()
      return
    }

    selectedEditable.DOMElement.innerText = value
    makeEditableValid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const resetDropdownValue = () => {
    selectedEditable.DOMElement.innerText = selectedEditable.label
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const updateFreetypeValue = value => {
    if (value === selectedEditable.label) {
      resetFreetypeValue()
      return
    }

    selectedEditable.DOMElement.innerText = value

    const hasNoValue = !(value?.trim().length > 0)

    if(hasNoValue && selectedEditable.isMandatory){
      makeEditableInvalid(selectedEditable.DOMElement)      
    }else{
      makeEditableValid(selectedEditable.DOMElement)      
    }
    deselectEditable()
  }

  const resetFreetypeValue = () => {
    selectedEditable.DOMElement.innerText = selectedEditable.label
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const updateDateValue = date => {
    const formatted = new moment(date).format('DD/MM/YYYY')
    selectedEditable.DOMElement.innerText = formatted
    makeEditableValid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const resetDateValue = () => {
    selectedEditable.DOMElement.innerText = selectedEditable.label
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const updateTimeValue = time => {
    const formatted = new moment(time).format('HH:mm')
    selectedEditable.DOMElement.innerText = formatted
    makeEditableValid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const resetTimeValue = () => {
    selectedEditable.DOMElement.innerText = selectedEditable.label
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const updateHtmlEditorState = html => {
    const prePopulatedState =
      editableLookups[selectedEditable.id].prePopulatedState
    const currentLabel = selectedEditable.label

    //REMOVE ANYTHING BUT WORDS TO DO A FAIR COMPARISON
    var virtualDiv = document.createElement('div')
    virtualDiv.innerHTML = html
    var strippedHtml = virtualDiv.innerText.replace(/[^\w]/g, '').toLowerCase()
    virtualDiv.innerHTML = prePopulatedState
    var strippedPrePopHtml = virtualDiv.innerText
      .replace(/[^\w]/g, '')
      .toLowerCase()
    var strippedCurrentLabel = currentLabel.replace(/[^\w]/g, '').toLowerCase()

    if (
      !strippedHtml.length > 0 ||
      (prePopulatedState && strippedHtml === strippedPrePopHtml) ||
      (!prePopulatedState && strippedHtml === strippedCurrentLabel)
    ) {
      resetHtmlEditorState()
      return
    }

    setHtmlEditorState({
      ...htmlEditorState,
      [selectedEditable.id]: html,
    })
    selectedEditable.DOMElement.innerHTML = html
    makeEditableValid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const resetHtmlEditorState = () => {
    const prePopulatedState =
      editableLookups[selectedEditable.id].prePopulatedState
    const editableType =
      editableLookups[selectedEditable.id].inputTypeId

    var defaultValue = prePopulatedState || `<p>${selectedEditable.label}</p>`

    if (editableType == editabletypesConstants.HtmlEditor)
    {
      defaultValue = prePopulatedState || selectedEditable.label
    }

    setHtmlEditorState({
      ...htmlEditorState,
      [selectedEditable.id]: defaultValue,
    })
    selectedEditable.DOMElement.innerHTML = defaultValue
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const updateCarouselValue = (selectedId, selectedHtml) => {
    setCarouselState({ ...carouselState, [selectedEditable.id]: selectedId })
    selectedEditable.DOMElement.innerHTML = selectedHtml
    makeEditableValid(selectedEditable.DOMElement)
    deselectEditable()
  }

  const resetCarouselValue = () => {
    selectedEditable.DOMElement.innerHTML = `<p>${selectedEditable.label}</p>`
    setCarouselState({ ...carouselState, [selectedEditable.id]: 0 })
    makeEditableInvalid(selectedEditable.DOMElement)
    deselectEditable()
  }

  // calculate progress
  useEffect(() => {
    if (!showEditableDialog) {
      calculateProgress()
    }
  }, [editableLookups, showEditableDialog, calculateProgress])

  const refreshPages = () => {
    const { branding = { headerHtml: '', footerHtml: '' } } = documentData
    const pagesContainer = document.getElementById('pages')
    const originalHtml = getDocumentHTML(pagesContainer)

    pagesContainer.innerHTML = ''
    setupPages({
      content: originalHtml,
      header: branding.headerHtml,
      footer: branding.footerHtml,
    })
    addEditableClickHandlers(getAllEditables(), selectEditable)
  }

  const PopoutComponent = () => {
    switch (selectedEditable.inputTypeId) {
      case InputTypes.FreeText:
        return (
          <FreetypeDialog
            title={selectedEditable.label}
            description={selectedEditable.description}
            open={showEditableDialog}
            onConfirm={e => withUnsavedChanges(updateFreetypeValue(e))}
            onReset={() => withUnsavedChanges(resetFreetypeValue())}
            onClose={deselectEditable}
            currentValue={selectedEditable.DOMElement.innerText}
          />
        )
      case InputTypes.DatePicker:
        return (
          <DatePickerDialog
            title={selectedEditable.label}
            description={selectedEditable.description}
            open={showEditableDialog}
            onConfirm={e => withUnsavedChanges(updateDateValue(e))}
            onReset={() => withUnsavedChanges(resetDateValue())}
            onClose={deselectEditable}
            currentDate={selectedEditable.DOMElement.innerText}
          />
        )
      case InputTypes.TimePicker:
        return (
          <TimePickerDialog
            title={selectedEditable.label}
            description={selectedEditable.description}
            open={showEditableDialog}
            onConfirm={e => withUnsavedChanges(updateTimeValue(e))}
            onReset={() => withUnsavedChanges(resetTimeValue())}
            onClose={deselectEditable}
            currentTime={new moment()}
          />
        )
      case InputTypes.Dropdown:
        return (
          <DropdownDialog
            title={selectedEditable.label}
            description={selectedEditable.description}
            open={showEditableDialog}
            onConfirm={e => withUnsavedChanges(updateDropdownValue(e))}
            onReset={() => withUnsavedChanges(resetDropdownValue())}
            onClose={deselectEditable}
            currentValue={selectedEditable.DOMElement.innerText}
            dropdownItems={selectedEditable.options}
          />
        )
      case InputTypes.HtmlEditor:
        return (
          <HtmlDialog
            title={selectedEditable.label}
            description={selectedEditable.description}
            open={showEditableDialog}
            currentHtml={htmlEditorState[selectedEditable.id]}
            onConfirm={e => withUnsavedChanges(updateHtmlEditorState(e))}
            onReset={() => withUnsavedChanges(resetHtmlEditorState())}
            onClose={deselectEditable}
            fontSize={documentData.fontSize}
            fontFamily={documentData.fontFamily}
          />
        )
      case InputTypes.Carousel:
        return (
          <CarouselDialog
            title={selectedEditable.label}
            open={showEditableDialog}
            onConfirm={(id, html) =>
              withUnsavedChanges(updateCarouselValue(id, html))
            }
            onReset={() => withUnsavedChanges(resetCarouselValue())}
            onClose={deselectEditable}
            currentIndex={carouselState[selectedEditable.id]}
            carouselOptions={selectedEditable.options}
            fontSize={documentData.fontSize}
            fontFamily={documentData.fontFamily}
          />
        )
      default:
        throw new Error('Editable using un-recognised input type id')
    }
  }

  const ConfirmationDialog = () => {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='xs'
        aria-labelledby='confirmation-dialog-title'
        open={openReviewModal}>
        <DialogTitle id='confirmation-dialog-title'>
          Send For Review
        </DialogTitle>
        <DialogContent dividers>
          If you send this document for review you will lose the ability to edit
          it until the review has been completed. Are you sure you want to
          submit it now?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelReviewModal} color='primary'>
            Cancel
          </Button>
          <Button onClick={setInReview} color='secondary'>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleCancelReviewModal = () => {
    setOpenReviewModal(false)
  }

  const CompletionConfirmationModal = () => {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='xs'
        aria-labelledby='confirmation-dialog-title'
        open={completionConfirmationModal}>
        <DialogTitle id='confirmation-dialog-title'>
          Complete Document
        </DialogTitle>
        <DialogContent dividers>
          Once a document is complete it cannot be edited or re-issued
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCancelCompletionModal}
            color='primary'>
            Cancel
          </Button>
          <Button onClick={() => completeDocument()} color='secondary'>
            Complete
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleCancelCompletionModal = () => {
    setCompletionConfirmationModal(false)
  }

  const reviewDocument = async () => {
    setOpenReviewModal(false)
    const pagesContainer = document.getElementById('pages')
    try {
      await wizardService.reviewDocument({
        documentGuid: documentData.documentGuid,
        contentHtml: getDocumentHTML(pagesContainer),
      })
      dispatch(
        enqueueSnackbar({
          message: 'Document sent for review',
          options: {
            variant: 'success',
          },
        })
      )
      returnToUrl()
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'Could not send document for review. Please try again.',
          options: {
            variant: 'error',
          },
        })
      )
    }
  }

  const endReview = async () => {
    setDocumentStatus(DocumentStatus.ReviewEnded)
    endReviewDocument().then(() => {
      saveDocument({
        returnAfterSave: true,
        status: DocumentStatus.ReviewEnded,
      })
    })
  }

  const endReviewDocument = async () => {
    const pagesContainer = document.getElementById('pages')

    try {
      setIsSaving(true)

      await wizardService.endReviewDocument({
        documentGuid: documentData.documentGuid,
        contentHtml: getDocumentHTML(pagesContainer),
      })

      setUnsavedChanges(false)
      setIsSaving(false)
      returnToUrl()
    } catch (error) {
      setIsSaving(false)
      dispatch(
        enqueueSnackbar({
          message: 'Could not save document.',
          options: {
            variant: 'error',
          },
        })
      )
    }
  }

  const setInReview = async () => {
    setDocumentStatus(DocumentStatus.AdviserReview)
    await reviewDocument().then(() => {
      saveDocument({
        returnAfterSave: true,
        status: DocumentStatus.AdviserReview,
      })
    })
  }

  const completeDocument = async () => {
    setCompletionConfirmationModal(false)
    removeNonMandatoryFieldsOnCompletion()

    const pagesContainer = document.getElementById('pages')
    const parsedQuery = queryString.parse(location.search)
    const {
      existingDocumentGuid,
      documentGuid,
      templateId,
      caseId,
      eventSetupId,
      workflowComponentId,
      workflowVersion,
    } = parsedQuery

    try {
      setIsCompleting(true)
      const { isCompleted } = await wizardService.completeDocument({
        documentGuid: documentData.documentGuid,
        contentHtml: getDocumentHTML(pagesContainer),
        caseId: caseId,
        eventSetupId: eventSetupId,
        workflowComponentId: workflowComponentId,
        workflowVersion: workflowVersion,
      })
      if (isCompleted) {
        setDocumentStatus(DocumentStatus.Completed)
        setUnsavedChanges(false)
        returnToUrl()
      }
      dispatch(
        enqueueSnackbar({
          message: 'Completed document',
          options: {
            variant: 'success',
          },
        })
      )
      setIsCompleting(false)
    } catch (error) {
      setIsCompleting(false)
      dispatch(
        enqueueSnackbar({
          message: 'Could not complete document. Please try again.',
          options: {
            variant: 'error',
          },
        })
      )
    }
  }

  const goBack = () => {
    if (!unsavedChanges) {
      returnToUrl()
      return
    }

    setIsConfirmExitModalOpen(true)
  }

  const saveDocument = async ({ returnAfterSave, status }) => {
    const pagesContainer = document.getElementById('pages')
    const parsedQuery = queryString.parse(location.search)
    const {
      existingDocumentGuid,
      documentGuid,
      templateId,
      caseId,
      eventSetupId,
      workflowComponentId,
      workflowVersion,
    } = parsedQuery

    try {
      setIsSaving(true)
      await wizardService.saveDocument({
        documentGuid: documentData.documentGuid,
        contentHtml: getDocumentHTML(pagesContainer),
        status: status || documentStatus,
        inReviewWithChanges: managerMadeChanges,
        caseId: caseId,
        eventSetupId: eventSetupId,
        workflowComponentId: workflowComponentId,
        workflowVersion: workflowVersion,
      })

      setUnsavedChanges(false)
      setIsSaving(false)

      dispatch(
        enqueueSnackbar({
          message: 'Saved document',
          options: {
            variant: 'success',
          },
        })
      )

      if (returnAfterSave) {
        returnToUrl()
      }
    } catch (error) {
      setIsSaving(false)
      dispatch(
        enqueueSnackbar({
          message: 'Could not save document.',
          options: {
            variant: 'error',
          },
        })
      )
    }
  }

  const previewDocument = async () => {
    const pagesContainer = document.getElementById('pages')
    try {
      setIsDownloading(true)
      wizardService
        .getPdfPreview({
          documentGuid: documentData.documentGuid,
          documentHtml: getDocumentHTML(pagesContainer),
        })
        .then(response => {
          downloadFile(response)
          setIsDownloading(false)
        })
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'Could not download preview. Please try again.',
          options: {
            variant: 'error',
          },
        })
      )
      setIsDownloading(false)
    }
  }

  const rightSubNav = () => {
    const parsedQuery = queryString.parse(location.search)
    const { isUserCaseParticipant } = parsedQuery

    // TODO
    // Toggle this to "true" when client applications pass the value on the QueryString
    // i.e. in the final version, if not supplied, assume they are a Case Participant
    // In initial version, assume the end user is NOT a Case Participant if the value is not on the QueryString
    // (and that they won't End Review on a Case if they are)
    const isUserCaseParticipantDefaultValue = false

    const isUserCaseParticipantValue =
      isUserCaseParticipant === undefined || isUserCaseParticipant === null
        ? isUserCaseParticipantDefaultValue
        : isUserCaseParticipant === 'true'

    const userCanEndReview = hasHrUserRole && !isUserCaseParticipantValue
    const documentCanEndReview = !documentReviewEnded && !documentCompleted
    const canEndReview = userCanEndReview && documentCanEndReview

    return (
      <>
        {unsavedChanges && (
          <Chip
            className={classes.unsavedChangesChip}
            label='Unsaved Changes'
            variant='outlined'
          />
        )}
        <SubNavButton
          handleClick={previewDocument}
          label={'Preview'}
          disabled={isCompleting || isSaving || isDownloading}
        />
        <SubNavButton
          handleClick={saveDocument}
          label={
            !isAdviser && docIsUnsavedAndAdviserDependant()
              ? 'In Progress'
              : 'Save'
          }
          disabled={
            documentCompleted ||
            !unsavedChanges ||
            isCompleting ||
            isSaving ||
            isDownloading
          }
        />
        {isAdviser &&
          docIsAdviserDependant() &&
          (documentInProgress ? (
            <SubNavButton
              handleClick={setInReview}
              label={'Set To In Review'}
            />
          ) : (
            <>
              {canEndReview && (
                <SubNavButton handleClick={endReview} label='End Review' />
              )}
            </>
          ))}
        {renderCompleteOption()}
        {(isCompleting || isSaving || isDownloading) && (
          <CircularProgress
            size={24}
            className={classes.modalSaveButtonProgress}
          />
        )}
      </>
    )
  }

  const renderCompleteOption = () => {
    if (managerMadeChanges) {
      return (
        <SubNavButton
          handleClick={() => setOpenReviewModal(true)}
          label='Send For Review'
          disabled={isSaving || documentInReview || isCompleting}
        />
      )
    }

    if (canComplete()) {
      return (
        <SubNavButton
          handleClick={() => {
            setCompletionConfirmationModal(true)
          }}
          label='Complete'
          disabled={
            documentCompleted ||
            progress < 100 ||
            documentInReview ||
            isCompleting ||
            isSaving ||
            isDownloading
          }
        />
      )
    }
    return null
  }

  return (
    <MainLayout
      rightSubNav={rightSubNav}
      progressPercentage={progress}
      onBackClick={goBack}>
      <Container className={classes.contentContainer}>
        {(!documentInReview || (isAdviser && documentInReview)) &&
          selectedEditable &&
          !documentCompleted && <PopoutComponent />}
        {(isBusinessUser || !isAdviser) && documentInReview && (
          <p className={classes.previewDisclaimer}>
            <small>
              <em>Document is in review, editing is disabled</em>
            </small>
          </p>
        )}

        <div
          className={!Object.keys(documentData)?.length ? classes.hide : null}>
          <h1>{documentData?.documentName || ''}</h1>
          <div
            className={classes.pages}
            ref={pagesRefCallback}
            id='pages'></div>
        </div>
        <div className={Object.keys(documentData).length ? classes.hide : null}>
          <LoadingPage text='Loading Document' />
        </div>
      </Container>
      <ScrollToTop />
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={isConfirmExitModalOpen}
        onClose={() => setIsConfirmExitModalOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Unsaved Changes'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            You have unsaved changes. Are you sure you wish to exit without
            saving them?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSaving}
            onClick={() => setIsConfirmExitModalOpen(false)}>
            Cancel
          </Button>
          <Button
            disabled={isSaving}
            onClick={returnToUrl}
            variant='outlined'
            color='secondary'>
            Exit without saving
          </Button>
          <div className={classes.modalSaveButtonWrapper}>
            <Button
              disabled={isSaving}
              onClick={() => saveDocument({ returnAfterSave: true })}
              variant='contained'
              color='secondary'>
              Save &amp; Exit
            </Button>
            {isSaving && (
              <CircularProgress
                size={24}
                className={classes.modalSaveButtonProgress}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog />
      <CompletionConfirmationModal />
    </MainLayout>
  )

  function canComplete() {
    return (
      (reviewType === reviewTypeConstants.AdviserCompletion && isAdviser) ||
      (reviewType !== reviewTypeConstants.AdviserCompletion &&
        !managerMadeChanges)
    )
  }

  function docIsAdviserDependant() {
    return (
      reviewType === reviewTypeConstants.AdviserReview ||
      reviewType === reviewTypeConstants.AdviserCompletion
    )
  }

  function docIsUnsavedAndAdviserDependant() {
    return !unsavedChanges && docIsAdviserDependant()
  }
}

export default Wizard
