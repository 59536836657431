import httpClient from '../utils/axios'
import {
  handleServiceResponse,
  handleServiceError,
} from '../utils/serviceResponseHelper'

const reviewDocument = async ({ documentGuid, contentHtml }) => {
  return httpClient
    .put(`${window.config.DOCUMENT_API_URL}/v1/document/review`, {
      documentGuid: documentGuid,
      contentHtml,
    })
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const completeDocument = async ({ documentGuid, contentHtml, caseId, eventSetupId, workflowComponentId, workflowVersion  }) => {
  const parsedCaseId = parseInt(caseId)
  const parseEventSetupId = parseInt(eventSetupId)
  const parseWorkflowComponentId = parseInt(workflowComponentId)
  const parsedWorkflowVersion = parseInt(workflowVersion)

  return httpClient
    .put(`${window.config.DOCUMENT_API_URL}/v1/document/complete`, {
      documentGuid: documentGuid,
      contentHtml,
      caseId: isNaN(parsedCaseId) ? 0 : parsedCaseId,
      eventSetupId: isNaN(parseEventSetupId) ? null: parseEventSetupId,
      workflowComponentId: isNaN(parseWorkflowComponentId) ? null : parseWorkflowComponentId,
      workflowVersion: parsedWorkflowVersion
    })
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const saveDocument = async ({ documentGuid, contentHtml, status, caseId, eventSetupId, workflowComponentId, inReviewWithChanges, workflowVersion }) => {
  const parsedCaseId = parseInt(caseId)
  const parseEventSetupId = parseInt(eventSetupId)
  const parseWorkflowComponentId = parseInt(workflowComponentId)
  const parsedWorkflowVersion = parseInt(workflowVersion)

  return httpClient
    .put(`${window.config.DOCUMENT_API_URL}/v1/document/update`, {
      documentGuid: documentGuid,
      contentHtml,
      currentStatus: status,
      caseId: isNaN(parsedCaseId) ? 0 : parsedCaseId,
      eventSetupId: isNaN(parseEventSetupId) ? null: parseEventSetupId,
      workflowComponentId: isNaN(parseWorkflowComponentId) ? null : parseWorkflowComponentId,
      inReviewWithChanges,
      workflowVersion: parsedWorkflowVersion
    })
    .then( 
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const endReviewDocument = async ({ documentGuid, contentHtml }) => {
  return httpClient
    .put(`${window.config.DOCUMENT_API_URL}/v1/document/endreview`, {
      documentGuid: documentGuid,
      contentHtml,
    })
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const createDocument = async ({ documentTemplateId, documentGuid, caseId, fromAddressUserId }) => {
  const parsedDocumentTemplateId = parseInt(documentTemplateId)
  const parsedCaseId = parseInt(caseId)
  return httpClient
    .post(`${window.config.DOCUMENT_API_URL}/v1/document/create`, {
      documentTemplateId: isNaN(parsedDocumentTemplateId)
        ? 0
        : parsedDocumentTemplateId,
      caseId: isNaN(parsedCaseId) ? 0 : parsedCaseId,
      documentGuid: documentGuid,
      fromAddressUserId:fromAddressUserId
    })
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const continueDocument = async ({ existingDocumentGuid}) => {
  return httpClient
    .get(
      `${window.config.DOCUMENT_API_URL}/v1/document/continue?existingDocumentGuid=${existingDocumentGuid}`
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const getPdfPreview = async ({ documentGuid, documentHtml }) => {
  return httpClient
    .post(
      `${window.config.DOCUMENT_API_URL}/v1/pdf/GetPdfPreview`,{
        documentGuid: documentGuid,
        documentHtml: documentHtml
      }
    ).then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

export default {
  reviewDocument,
  completeDocument,
  saveDocument,
  createDocument,
  continueDocument,
  endReviewDocument,
  getPdfPreview,
}
