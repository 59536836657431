import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Typography } from '@material-ui/core'
import EditableDialogWrapper from './editableDialogWrapper'
import PropTypes from 'prop-types'
import moment from 'moment'
import { KeyboardTimePicker } from '@material-ui/pickers'

const TimePickerDialog = ({
  title,
  description,
  open,
  onConfirm,
  onReset,
  onClose,
  currentTime,
}) => {
  const [time, setTime] = useState(
    new moment(currentTime, 'HH:mm').isValid()
      ? new moment(currentTime, 'HH:mm')
      : new moment()
  )

  return (
    <EditableDialogWrapper title={title} open={open} onClose={onClose}>
      <DialogContent dividers>
        {description && <Typography gutterBottom>{description}</Typography>}
        <KeyboardTimePicker
          invalidDateMessage={'24 hours format'}
          ampm={false}
          format='HH:mm'
          value={time}
          onChange={selectedTime => setTime(selectedTime)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onReset(new moment())} color='secondary'>
          Reset
        </Button>
        <Button
          variant='contained'
          onClick={() => onConfirm(time)}
          color='secondary'
          disabled={!(new moment(time).isValid())}>
          Confirm
        </Button>
      </DialogActions>
    </EditableDialogWrapper>
  )
}

TimePickerDialog.propTyes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  currentTime: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default TimePickerDialog
