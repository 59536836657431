import React, { useState, useRef, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { makeStyles } from '@material-ui/core/styles'
import AdminCard from './adminCard'
import AdminDocumentPreview from './adminDocumentPreview'
import ActionBar from './actionBar'
import { useService } from '../contexts/serviceContext'

const useStyles = makeStyles({
  formControl: { marginBottom: '20px', width: 200 },
  textbox: { minHeight: 260 },
})

const DocumentBrandingForm = ({
  initialFormData,
  currentBrandings,
  onSubmit,
}) => {
  const classes = useStyles()

  const [isEditMode, setIsEditMode] = useState(true)

  const [name, setName] = useState('')
  const [nameIsValid, setNameIsValid] = useState(false)
  const [nameValidationMessage, setNameValidationMessage] = useState('')

  const [isArchived, setIsArchived] = useState(false)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setIsEditMode(initialFormData.id > 0)
    setName(initialFormData.name)
  }, [initialFormData])

  const brandingNameMaxCharacters = 100
  const brandingNameMinCharacters = 3

  useEffect(() => {
    if (name.length < brandingNameMinCharacters) {
      setNameValidationMessage(
        `Enter at least ${brandingNameMinCharacters} characters`
      )
      setNameIsValid(false)
    } else if (name.length > brandingNameMaxCharacters) {
      setNameValidationMessage(
        `Enter ${brandingNameMaxCharacters} or fewer characters`
      )
      setNameIsValid(false)
    } else if (
      currentBrandings &&
      currentBrandings.find(x => x.name.toLowerCase() === name.toLowerCase())
    ) {
      setNameValidationMessage('This name has already been taken')
      setNameIsValid(false)
    } else {
      setNameIsValid(true)
      setNameValidationMessage('Valid Input')
    }
  }, [name, currentBrandings])

  const [steps, setSteps] = useState([])

  useEffect(() => {
    setSteps(
      isEditMode
        ? [
            'Update branding',
            'Update the header',
            'Update the footer',
            'Preview and confirm',
          ]
        : [
            'Create a branding',
            'Add a header',
            'Add a footer',
            'Preview and confirm',
          ]
    )
  }, [isEditMode])

  const [activeStep, setActiveStep] = useState(0)
  const [isActiveStepValid, setIsActiveStepValid] = useState(false)

  const validateActiveStep = () => {
    if (activeStep === 0 && nameIsValid) return true
    if (activeStep === 1 && headerHtmlIsValid) return true
    if (activeStep === 2 && footerHtmlIsValid) return true
    return false
  }

  const handleSubmit = e => {
    if (isValid) {
      e.preventDefault()
      var returnedData = {
        id: initialFormData.id,
        name: name,
        serviceId: initialFormData.serviceId,
        isArchived: isArchived,
        headerHtml: headerHtml,
        footerHtml: footerHtml,
      }
      onSubmit(returnedData)
    }
  }

  const editorTools = [
    'bold',
    'italic',
    'underline',
    // "strikethrough",
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'justifyFull',
    'insertUnorderedList',
    'insertOrderedList',
    'indent',
    'outdent',
    // "createLink",
    // "unlink",
    'insertImage',
    // "insertFile",
    // "subscript",
    // "superscript",
    'tableWizard',
    'createTable',
    'addRowAbove',
    'addRowBelow',
    'addColumnLeft',
    'addColumnRight',
    'deleteRow',
    'deleteColumn',
    'mergeCellsHorizontally',
    'mergeCellsVertically',
    'splitCellHorizontally',
    'splitCellVertically',
    'viewHtml',
    'formatting',
    'cleanFormatting',
    'copyFormat',
    'applyFormat',
    // 'fontName',
    // 'fontSize',
    'foreColor',
    'backColor',
    // "print"
  ]

  const headerEditorWrapperRef = useRef()
  const headerEditorRef = useRef()
  const [headerHtml, setHeaderHtml] = useState(initialFormData.headerHtml)
  const [headerHtmlIsValid, setHeaderHtmlIsValid] = useState(false)
  const [
    headerHtmlValidationMessage,
    setHeaderHtmlValidationMessage,
  ] = useState('')

  const onHeaderChange = () => headerEditorChanged()

  const headerEditorChanged = () => {
    let $ = window.jQuery
    const editor = $(headerEditorRef.current).data('kendoEditor')
    if (editor) setHeaderHtml(editor.value())
  }

  useEffect(() => setHeaderHtmlIsValid(headerHtml.length > 10), [headerHtml])
  useEffect(
    () =>
      setHeaderHtmlValidationMessage(
        headerHtmlIsValid ? 'Valid Input' : 'Enter a header'
      ),
    [headerHtmlIsValid]
  )

  const { serviceConfig } = useService()

  useEffect(() => {
    let $ = window.jQuery
    if (activeStep === 1) {
      $(headerEditorRef.current).kendoEditor({
        stylesheets: [
          `/css/editor/font-family-${serviceConfig.fontFamily}.css`,
          `/css/editor/font-size-${serviceConfig.fontSize}.css`,
        ],
        tools: editorTools,
        change: onHeaderChange,
        select: onHeaderChange,
        execute: onHeaderChange,
        paste: onHeaderChange,
      })
      var editor = $(headerEditorRef.current).data('kendoEditor')
      editor.value(headerHtml)
      headerEditorWrapperRef.current.style.display = 'block'
    }
  }, [activeStep])

  const footerEditorWrapperRef = useRef()
  const footerEditorRef = useRef()
  const [footerHtml, setFooterHtml] = useState(initialFormData.footerHtml)
  const [footerHtmlIsValid, setFooterHtmlIsValid] = useState(false)
  const [
    footerHtmlValidationMessage,
    setFooterHtmlValidationMessage,
  ] = useState('')

  const onFooterChange = () => footerEditorChanged()

  const footerEditorChanged = () => {
    let $ = window.jQuery
    const editor = $(footerEditorRef.current).data('kendoEditor')
    if (editor) setFooterHtml(editor.value())
  }

  useEffect(() => setFooterHtmlIsValid(footerHtml.length > 10), [footerHtml])
  useEffect(
    () =>
      setFooterHtmlValidationMessage(
        footerHtmlIsValid ? 'Valid Input' : 'Enter a footer'
      ),
    [footerHtmlIsValid]
  )

  useEffect(() => {
    if (activeStep === 2) {
      let $ = window.jQuery
      $(footerEditorRef.current).kendoEditor({
        tools: editorTools,
        change: onFooterChange,
        select: onFooterChange,
        execute: onFooterChange,
        paste: onFooterChange,
      })
      var editor = $(footerEditorRef.current).data('kendoEditor')
      editor.value(footerHtml)
      footerEditorWrapperRef.current.style.display = 'block'
    }
  }, [activeStep])

  useEffect(() => {
    setNameValidationMessage(nameValidationMessage)
    setIsValid(nameIsValid && headerHtmlIsValid && footerHtmlIsValid)
    setIsActiveStepValid(() => validateActiveStep())
  }, [nameIsValid, activeStep, headerHtmlIsValid, footerHtmlIsValid])

  return (
    <>
      <AdminCard>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </AdminCard>
      <form onSubmit={handleSubmit}>
        {activeStep === 0 && (
          <AdminCard title={'Overview'}>
            <div>
              <FormControl className={classes.formControl} error={!nameIsValid}>
                <TextField
                  disabled={isEditMode}
                  type='text'
                  label='Name'
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <FormHelperText>{nameValidationMessage}</FormHelperText>
              </FormControl>
            </div>
            {isEditMode ? (
              <div>
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isArchived}
                        onChange={e => setIsArchived(e.target.checked)}
                        color='primary'
                      />
                    }
                    label='Archived?'
                  />
                </FormControl>
              </div>
            ) : null}
          </AdminCard>
        )}
        {activeStep === 1 && (
          <AdminCard title={'Header'}>
            <div ref={headerEditorWrapperRef} style={{ display: 'none' }}>
              <textarea ref={headerEditorRef}></textarea>
            </div>
            <FormHelperText error={!headerHtmlIsValid}>
              {headerHtmlValidationMessage}
            </FormHelperText>
          </AdminCard>
        )}
        {activeStep === 2 && (
          <AdminCard title={'Footer'}>
            <div ref={footerEditorWrapperRef} style={{ display: 'none' }}>
              <textarea ref={footerEditorRef}></textarea>
            </div>
            <FormHelperText error={!footerHtmlIsValid}>
              {footerHtmlValidationMessage}
            </FormHelperText>
          </AdminCard>
        )}

        {activeStep === 3 && (
          <AdminDocumentPreview
            headerHtml={headerHtml}
            footerHtml={footerHtml}
            contentHtml={'Document Content'}
          />
        )}
        <ActionBar
          leftContent={[
            {
              label: 'Back',
              show: activeStep !== 0,
              isPrimaryAction: false,
              onClick: () =>
                setActiveStep(prevActiveStep => prevActiveStep - 1),
            },
            {
              label: 'Next',
              show: activeStep !== steps.length - 1,
              isPrimaryAction: false,
              disabled: !isActiveStepValid,
              onClick: () =>
                setActiveStep(prevActiveStep => prevActiveStep + 1),
            },
          ]}
          rightContent={[
            {
              label: isEditMode ? 'Update' : 'Create',
              show: activeStep === steps.length - 1,
              isPrimaryAction: true,
              disabled: !isValid,
              type: 'submit',
            },
          ]}
        />
      </form>
    </>
  )
}

export default DocumentBrandingForm
