import React, { useState } from 'react'
import {
  Typography,
  Button,
  DialogActions,
  DialogContent,
  Input,
} from '@material-ui/core'
import EditableDialogWrapper from './editableDialogWrapper'
import PropTypes from 'prop-types'

const FreetypeDialog = ({
  title,
  description,
  open,
  onConfirm,
  onReset,
  onClose,
  currentValue,
}) => {
  const [content, setContent] = useState(currentValue)
  return (
    <EditableDialogWrapper title={title} open={open} onClose={onClose}>
      <DialogContent dividers>
        {description && <Typography gutterBottom>{description}</Typography>}
        <Input
          defaultValue={content === title ? '' : content}
          onChange={e => setContent(e.target.value)}
          placeholder={title}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onReset(title)} color='secondary'>
          Reset
        </Button>
        <Button
          variant='contained'
          onClick={() => onConfirm(content)}
          color='secondary'>
          Confirm
        </Button>
      </DialogActions>
    </EditableDialogWrapper>
  )
}

FreetypeDialog.propTyes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentValue: PropTypes.string.isRequired,
}

export default FreetypeDialog
