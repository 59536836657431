import React, { useState, useEffect } from 'react'
import {
  Typography,
  Button,
  DialogActions,
  DialogContent,
  Chip,
} from '@material-ui/core'
import { ArrowBackRounded, ArrowForwardRounded } from '@material-ui/icons'
import EditableDialogWrapper from './editableDialogWrapper'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  carouselWrapper: {
    width: '500px',
    overflowY: 'auto',
    position: 'relative',
  },
  carouselHeader: {
    display: 'flex',
    width: '100%',
  },
  carouselBody: {
    overflowY: 'auto',
    maxHeight: '500px',
  },
  headerSection: {
    display: 'flex',
    flex: 1,
  },
  headerMiddle: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  navigationButton: {
    border: 'none',
    background: 'none',
    marginBottom: 'auto',
  },
  nextButton: {
    marginLeft: 'auto',
  },
  prevButton: {
    marginRight: 'auto',
  },
})

const CarouselDialog = ({
  title,
  open,
  onConfirm,
  onReset,
  onClose,
  currentIndex,
  carouselOptions,
  fontSize,
  fontFamily
}) => {
  const classes = useStyles()
  const [selectedIndex, setSelectedIndex] = useState(1)
  const indexIsSelected = carouselOptions[selectedIndex].id === currentIndex
  const [selectedId, setSelectedId] = useState(0)
  const [selectedHtml, setSelectedHtml] = useState('')

  useEffect(() => {
    const initialIndex = currentIndex
      ? carouselOptions.findIndex(x => x.id === currentIndex)
      : 0
    setSelectedIndex(initialIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSelectedId(carouselOptions[selectedIndex].id)
    setSelectedHtml(carouselOptions[selectedIndex].value)
  }, [selectedIndex])

  return (
    <EditableDialogWrapper title={title} open={open} onClose={onClose}>
      <DialogContent dividers>
      {carouselOptions[selectedIndex].description && <Typography gutterBottom>{carouselOptions[selectedIndex].description}</Typography>}
        <div className={classes.carouselHeader}>
          <div className={classes.headerSection}>
            {selectedIndex > 0 && (
              <button
                type='button'
                className={`${classes.navigationButton} ${classes.prevButton}`}
                onClick={() => setSelectedIndex(selectedIndex - 1)}>
                <ArrowBackRounded />
              </button>
            )}
          </div>
          <div className={`${classes.headerSection} ${classes.headerMiddle}`}>
            <span className={classes.selectionCounter}>
              {selectedIndex + 1}/{carouselOptions.length}
            </span>
            {indexIsSelected && (
              <Chip
                className={classes.selectedIndicator}
                variant='outlined'
                size='small'
                label='Selected'
                color='primary'
              />
            )}
          </div>
          <div className={classes.headerSection}>
            {selectedIndex < carouselOptions.length - 1 && (
              <button
                type='button'
                className={`${classes.navigationButton} ${classes.nextButton}`}
                onClick={() => setSelectedIndex(selectedIndex + 1)}>
                <ArrowForwardRounded />
              </button>
            )}
          </div>
        </div>
        <div
          className={classes.carouselBody}
          style={{ fontSize: fontSize, fontFamily: fontFamily }}
          dangerouslySetInnerHTML={{
            __html: carouselOptions[selectedIndex].value,
          }}></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onReset()} color='secondary'>
          Reset
        </Button>
        <Button
          variant='contained'
          onClick={() => onConfirm(selectedId, selectedHtml)}
          color='secondary'>
          Confirm
        </Button>
      </DialogActions>
    </EditableDialogWrapper>
  )
}

CarouselDialog.propTyes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  carouselOptions: PropTypes.array.isRequired,
}

export default CarouselDialog
